import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import GenericActivation from "../src/components/genericactivation/genericactivation.component.jsx";
import GenericDetails from "../src/components/genericdetails/genericdetails.component.jsx";
import ReportLost from "../src/components/genericreportlost/reportlost.component.jsx";
import GenericPoster from "../src/components/genericposter/genericposter.component.jsx";
import GenericOwner from "./components/genericowner/genericowner.component.jsx";
import ReportFound from "../src/components/reportfound/reportfound.component.jsx";
import EditReportLost from "../src/components/genericreportlost/editreportlost/editreportlost.component.jsx";
import Home from "../src/components/home/home.component.jsx";
import Navbar from "../src/components/header/header.component.jsx";
import ProtectedRoute from "./ProtectedRoute.js";
import FoundDetails from "../src/components/reportfound/founddetails/founddetails.component.jsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  let usertoken = readCookie("token");
  const uid = localStorage.getItem("uid");
  const dashboardRoute = "/dashboard/" + uid;
  const [isAuth, setIsAuth] = useState(usertoken !== null ? true : false);
  useEffect(() => {
    setIsAuth(usertoken !== null ? true : false);
  }, [isAuth]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HashRouter>
          <div className="App">
            {/* <Navbar /> */}
            <Switch>
              <Route
                path={"/generic/edit-report-lost/:tag_number"}
                component={EditReportLost}
                isAuth={isAuth}
              />
              <Route
                path={"/generic-poster/:tag_number"}
                component={GenericPoster}
                isAuth={isAuth}
              />
              <Route
                path={"/generic/report-lost/:tag_number"}
                component={ReportLost}
                isAuth={isAuth}
              />
              <Route
                path={"/generic-details/:tag_number"}
                component={GenericDetails}
                isAuth={isAuth}
              />
              <Route
                path={"/generic-activation/:tag_number"}
                render={(props) => <GenericActivation {...props} />}
              />
              {/* <Route path={'/beacon-owner-details/:tag_number'} component={KeyOwner} isAuth={isAuth}/> */}
              <Route
                path={"/report-found/:tag_number"}
                render={(props) => <FoundDetails {...props} />}
              />
              <Route
                path={"/scan/:tag_number"}
                render={(props) => <ReportFound {...props} />}
              />

              <ProtectedRoute
                path={"/generic-activation/:tag_number"}
                component={GenericActivation}
                isAuth={isAuth}
              />
              <Route path={"/"}>
                <Home />
              </Route>
            </Switch>
          </div>
        </HashRouter>
      </LocalizationProvider>
    </>
  );
}

export default App;
