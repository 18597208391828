import React, { useState, useRef, useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import "./sidebar.component.css";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { MdMenu, MdTag, MdNotifications } from "react-icons/md";
import { IoLogIn, IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import * as FcIcons from "react-icons/fc";
import { FaIdCardAlt } from "react-icons/fa";
import { BiCollapse } from "react-icons/bi";
import { Collapse } from "bootstrap";

const Sidebarcontent = ({
  open,
  onClose,
  handleShow,
  handleAllTags,
  handleActiveInactive,
  handleLostFound,
  showNotifications,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [sidebarTop, setSidebarTop] = useState("190px");
  const [isAnnouncementBarPresent, setIsAnnouncementBarPresent] =
    useState(false);
  const toggleButtonRef = useRef(null);
  const sidebarRef = useRef(null);
  const announcementBarRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (!mobile) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleToggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const handleCloseSidebar = () => {
    if (isCollapsed) {
      // console.log(isCollapsed, "close");
      setIsCollapsed(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      //event.preventDefault();

      // console.log(event.target, "clickend eve");

      if (
        isMobile &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        // console.log(toggleButtonRef, "clickd");
        handleCloseSidebar();
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [isMobile, handleToggleSidebar]);
  useEffect(() => {
    const updateSidebarPosition = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setSidebarTop("180px");
      } else {
        // Scrolling up or at the top
        if (scrollTop === 0) {
          // Check again after a short delay
          setTimeout(() => {
            let updatedAnnouncementBar = document.querySelector(
              ".announcement-bar-1-hidden"
            );
            if (!updatedAnnouncementBar) {
              // console.log("first");
              setSidebarTop("180px");
            } else {
              setSidebarTop("150px");
            }
          }, 100);
        } else {
          setSidebarTop("150px");
        }
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", updateSidebarPosition);

    return () => {
      window.removeEventListener("scroll", updateSidebarPosition);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.style.top = sidebarTop;
    }
  }, [sidebarTop]);

  const handleNotifications = () => {
    window.location.href =
      "/#/scan-notification/" + localStorage.getItem("uid");
  };
  // const handleShopNow = () => {
  //   window.location.href = "/shop"; // Redirect to the shop page
  // };
  return (
    <div className={`app ${isCollapsed ? "sidebar-collapsed" : ""}`}>
      {isMobile && (
        <button
          ref={toggleButtonRef}
          className="mobile-toggle-button"
          onClick={handleToggleSidebar}
        >
          <IoMenuOutline />
        </button>
      )}
      <div
        className={`sidebar ${isCollapsed ? "collapsed" : ""}`}
        ref={sidebarRef}
      >
        {!isMobile && (
          <button
            ref={toggleButtonRef}
            className="toggle-button"
            onClick={handleToggleSidebar}
          >
            <IoMenuOutline />
          </button>
        )}
        <div className="sidebar-menu">
          <div className="menu-item" onClick={handleToggleSubMenu}>
            <img
              className="sidebar-icons"
              src={
                "https://storage.googleapis.com/pettag/qr/assets/all-tag.png"
              }
              alt="Pet Icon"
            />
            <span>MY TAGS</span>
          </div>
          {subMenuOpen && (
            <div className="submenu">
              <div className="menu-item" onClick={handleAllTags}>
                <img
                  className="sidebar-sub-icons"
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/right-down.png"
                  }
                  alt="Pet Icon"
                />
                <span>All Tags</span>
              </div>
              <div className="menu-item" onClick={handleActiveInactive}>
                <img
                  className="sidebar-sub-icons"
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/right-down.png"
                  }
                  alt="Pet Icon"
                />
                <span>Active | Inactive Tags</span>
              </div>
              <div className="menu-item" onClick={handleLostFound}>
                <img
                  className="sidebar-sub-icons"
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/right-down.png"
                  }
                  alt="Pet Icon"
                />
                <span>Lost | Found Tags</span>
              </div>
            </div>
          )}
          <div className="menu-item" onClick={handleShow}>
            <img
              className="sidebar-icons"
              src={
                "https://storage.googleapis.com/pettag/qr/assets/activate.png"
              }
              alt="Pet Icon"
            />
            <span>ACTIVATE TAG</span>
          </div>
          <div className="menu-item" onClick={handleNotifications}>
            <img
              className="sidebar-icons"
              src={
                "https://storage.googleapis.com/pettag/qr/assets/notificaiton.png"
              }
              alt="Pet Icon"
            />
            <span>NOTIFICATIONS</span>
          </div>
          {/* <div className="shop-now-button-container">
            <button className="shop-now-button" onClick={handleShopNow}>
              Shop Now
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebarcontent;
