import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../genericdetails/genericdetails.component.css";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import "../reportfound/reportfound.component.css";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import Loader from "../../assets/loader.gif";
import { switchUrls } from "../../api/index.js";

const baseURL = switchUrls("generic");
export default class ReportFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: "",
      longitude: "",
      tag_number: this.props.match.params.tag_number,
      snackbaropen: false,
      snackbarmsg: "",
      notfound: false,
      notactivated: false,
      showLoader: true,
    };
    this.getCoordinates = this.getCoordinates.bind(this);
  }

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser.");
    }

    const initial = this.state.tag_number.substring(0, 3);
    axios
      .post("https://dashboard.tag8.in/api/checkTagNumberWithoutHeader", {
        tag_number: this.state.tag_number,
      })
      .then((res) => {
        if (res.data === "Tag not Found by the specified Number") {
          this.setState({ notfound: true });
        } else if (res.data === "Tag Activated Successfully") {
          this.setState({ notactivated: true });
        } else if (res.data === "Tag is already activated") {
          if (initial === "PET") {
            window.location.href = "/scan/" + this.state.tag_number;
          } else if (initial === "TGZ") {
            window.location.href = "/scan/" + this.state.tag_number;
          } else if (initial === "PAS") {
            window.location.href = "/scan/" + this.state.tag_number;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/generic/urlgenericreportfound/` + this.state.tag_number
      )
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
  }

  handleClose = () => {
    this.setState({ notfound: false });
  };
  handleClose1 = () => {
    this.setState({ notactivated: false });
  };
  onNotFound = () => {
    this.setState({ notfound: false });
    const tagnumber = this.props.match.params.tag_number;
    const initial = tagnumber.substring(0, 3);
    if (initial === "PET") {
      window.location.href = "/pet";
    } else if (initial === "KEY") {
      window.location.href = "/";
    } else if (initial === "TGZ") {
      window.location.href = "/pet";
    } else if (initial === "TGY") {
      window.location.href = "/";
    } else if (initial === "PAS") {
      window.location.href = "/";
    } else if (initial === "GEN") {
      window.location.href = "/";
    } else {
      window.location.href = "/login";
    }
  };
  onActivated = () => {
    window.location.href = "/scan";
  };

  getCoordinates(position) {
    console.log(position);
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    axios
      .post(
        `${baseURL}/api/generic/ScanLocation/` +
          this.props.match.params.tag_number,
        this.state
      )
      .then((response) => {
        console.log(response);
        if (response.data === "Location added") {
          window.location.href =
            "/report-found/" + this.props.match.params.tag_number;
        } else if (response.data === "Location could not be added") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Could not find location",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Location could not be added",
        });
      });
  };

  render() {
    const { latitude, longitude, tag_number } = this.state;
    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <div
        className="bg-image p-5 text-center hide"
        id="footer_footer_reportfound"
      >
        <form style={{ "margin-top": "420px" }} onSubmit={this.submitHandler}>
          <div className="row" hidden>
            <div className="col">
              <span className="label-petdata">Latitude</span>
              <br />
              <input
                type="textbox"
                className="input-pet-details"
                value={latitude}
                name="latitude"
                onChange={this.changeHandler}
                required
              />
            </div>
            <div className="col">
              <span className="label-petdata">Longitude</span>
              <br />
              <input
                type="textbox"
                className="input-pet-details"
                value={longitude}
                name="longitude"
                onChange={this.changeHandler}
                required
              />
            </div>
            <div className="col">
              <span className="label-petdata">Tag number</span>
              <br />
              <input
                type="textbox"
                className="input-pet-details"
                value={tag_number}
                name="tag_number"
                onChange={this.changeHandler}
                required
              />
            </div>
          </div>
          {latitude && longitude ? (
            <div></div>
          ) : (
            <div className="font-weight-bold allowlocationtext">
              Please allow location to view pet details.
            </div>
          )}

          <div className="row buttonforbutton">
            <div className="col text-center">
              <Button
                type="submit"
                className="keyfoundbutton bg-dark text-white text-center p-2"
                disabled={!latitude && !longitude}
                id="buttonA"
                style={{
                  width: "250px",
                  "margin-top": "80px",
                  "font-size": "20px",
                  "word-wrap": "break-word",
                  textTransform: "none",
                }}
              >
                View Key Details
              </Button>
            </div>
          </div>
          <Snackbar
            open={this.state.snackbaropen}
            autoHideDuration={3000}
            onClose={this.snackbarClose}
            message={this.state.snackbarmsg}
            action={[
              <IconButton
                key="close"
                arial-label="Close"
                color="inherit"
                onClick={this.snackbarClose}
              >
                X
              </IconButton>,
            ]}
          />
        </form>
        <Dialog
          disableBackdropClick
          open={this.state.notfound}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <h1
              style={{
                margin: "20px",
                marginTop: "10px",
                font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                letterSpacing: "normal",
              }}
            >
              Tag not Found by the specified Number
            </h1>
            <div className="text-center mb-4">
              <Button variant="outlined" onClick={this.onNotFound}>
                Ok
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          disableBackdropClick
          open={this.state.notactivated}
          onClose={this.handleClose1}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <h1
              style={{
                margin: "20px",
                marginTop: "10px",
                font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
                letterSpacing: "normal",
              }}
            >
              Tag is not activated
            </h1>
            <div className="text-center mb-4">
              <Button variant="outlined" onClick={this.onActivated}>
                Ok
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {this.state.showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
               src={
                              "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                            }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
